@import "primeng/resources/themes/bootstrap4-dark-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  @apply bg-kamadoor-black font-sans;
}
